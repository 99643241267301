.SideProjectItem-Container {
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.SideProjectItem-Content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.SideProjectItem-Image {
    max-width: 250px;
    /* box-shadow: 0px 4px 40px 40px rgba(200, 172, 50, 0.15); */
} 

a {
    text-decoration: none;
    color: var(--gvns-dark);
}

@media (min-width: 740px) {
    .SideProjectItem-Container {
        max-width: 225px;
        transition: transform .4s;
    }

    .SideProjectItem-Container:hover {
        cursor: crosshair;
        transform: scale(1.01);
        transition: box-shadow 1s, transform .4s;
    }

    .SideProjectItem-Image:hover {
        /* box-shadow: 0px 4px 15px 4px rgba(200, 172, 50, 0.45); */
    }

    .SideProjectItem-Container:active {
        color: var(--gvns-dark);
        transform: scale(99%);
        /* border-bottom: 6px solid var(--gvns-dark, #3D3D3D);
        box-shadow: 0px 4px 10px 4px rgba(200, 172, 50, 0);
        transition: box-shadow .05s; */
    }

    .SideProjectItem-Image {
        max-width: 250px;
        width: 100%;
    } 
}