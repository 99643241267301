.StudyHero-Container {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.StudyHero-Image {
    color: var(--beige-color);
    transition: transform .4s, filter .4s;
    color: var(--beige-color);
    line-height: 180%;
    font-family: var(--body-font);
    width: 100%;
    font-weight: 500;
    border-radius: 16px;
    background-color: rgba(0,0,0,0);
    filter: drop-shadow(0px 4px 30px rgba(0,0,0,0.07));
}

/* .Spotlight {
    height: 20px;
    width: 20px;
    background-color: yellow;
    filter: blur(8px);
} */

@media (min-width: 740px) {
    /* .StudyHero-Image:hover {
        transform: scale(1.004);
        filter: drop-shadow(0px 4px 30px rgba(0,0,0,0.1));
        transition: transform ease-in .2s, filter ease-in .2s;
    } */
}