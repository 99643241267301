:root {
    /* Colors */

    --title-color: #181A27;
    --body-color: #597281;
    --beige-color: #F8F8F5;
    --img-bg: #F5F5F5;
    --bg-color: #FAFAFA;

    --body-font: 'Inter', sans-serif;
    --title-font: 'Inter', sans-serif;
  }

  /* Text styles */

  h0 {
    font-family: var(--title-font);
    font-weight: 500;
    line-height: 120%;
    font-size: 56px;
    font-weight: 500;
    margin: 0;
    color: var(--title-color);
  }
  
  h1 {
    font-family: var(--title-font);
    font-weight: 300;
    line-height: 120%;
    font-size: 56px;
    margin: 0;
    color: var(--title-color);
  }

  h2 {
    font-family: var(--title-font);
    font-weight: 500;
    line-height: 130%;
    margin: 0;
    font-size: 32px;
    font-weight: 300;
    color: var(--title-color);
  }

  h3 {
    font-family: var(--body-font);
    font-weight: 500;
    line-height: 150%;
    font-size: 15px;
    margin: 0;
    padding: 0;
    color: var(--title-color);
  }

  h4 {
    font-size: 13px;
    font-family: var(--body-font);
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    color: var(--body-color);
  }

  h5 {
    font-size: 24px;
    font-family: var(--title-font);
    color: var(--title-color);
    font-weight: 300;
    margin: 0;
  }
  
  p {
    font-family: var(--body-font);
    font-weight: 400;
    line-height: 180%;
    font-size: 15px;
    margin: 0;
    color: var(--body-color);
  }

  .legend {
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 13px;
    color: var(--body-color);
    font-style: italic;
    margin: 0;
    opacity: 70%;
  }

  ul {
    line-height: 180%;
    list-style-type: none;
    margin-top: 4px;
  }

  li::before {
    content: "❃"; /* Replace with your custom character or icon */
    display: inline-block;
    margin-right: 8px; /* Adjust spacing between the character and the list item text */
    font-size: 12px; /* Adjust the size of the custom character */
    margin-left: -24px;
  }

  b {
    font-weight: 500;
  }

  .link {
    text-decoration: none;
    border-bottom: 1.5px solid var(--title-color);
    display: inline-block;
    line-height: 18px;
    cursor:crosshair;
    font-family: var(--body-font);
  }

  
  .skill {
    padding: 0px 1px;
  }
  
  .skill-Prototype {
    background: linear-gradient(270deg, rgba(255, 248, 228, .7) 0%, rgba(255, 229, 223, .7) 47.4%, rgba(255, 235, 235, .7) 100%);
    transition: background 2s;
  }

  .skill-Research {
    background-image: linear-gradient(270deg, rgba(217, 255, 244, 0.7) 0%, rgba(208, 255, 255, 0.7) 47.4%, rgba(235, 250, 255, 0.7) 100%);
  }
  
  .skill-Design {
    background: linear-gradient(270deg, rgba(228, 255, 236, 0.7) 0%, rgba(229, 255, 223, 0.7) 47.4%, rgba(251, 255, 235, 0.7) 100%);
  }
  