.Hero-Container {
    display: flex;
    flex-direction: column;
    gap: 32px;

}

.HeroImage {
    width: 100%;
    max-width: 250px;
    height: 250px;
    flex-wrap: wrap;
}

.Hero-Container-Value {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media (min-width: 740px) {
    .Hero-Container {
        flex-direction: row;
    }
}