.CaseStudyItem-Container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.CaseStudyItem-Container-Content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.CaseStudyItem-Container-Left {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.CaseStudyItem-Container-Titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

@media (min-width: 740px) {
    
    .CaseStudyItem-Container-Content {
        flex-direction: row;
    }

    .CaseStudyItem-Container-Content {
        gap: 40px;
    }

    .CaseStudyItem-Container-Titles {
        min-width: 400px;
    }
}