

.SectionHeader-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    opacity: .4;
}

.Divider {
    height: 1px;
    width: 100%;
    /* background-color: var(--title-color); */
    order: 1;
}

.Divider-1 {
    background: linear-gradient(0.25turn, var(--title-color), rgba(89,114,129,0));
}

.Divider-2 {
    display: none;
    background: linear-gradient(0.25turn, rgba(89,114,129,0), var(--title-color));
}

.SectionHeader-Container-Name {
    width: 100%;
    text-align: left;
    order: 2;
}

.SectionHeader-Container-Description {
    order: 3;
    width: 100%;
}

@media (min-width: 740px) {
    .SectionHeader-Container {
        flex-direction: row;
        gap: 16px;
    }

    .SectionHeader-Container > p {
        max-width: 250px;
    }

    .SectionHeader-Container-Description {
        order: 3;
    }

    .SectionHeader-Container-Name {
        order: 3;
        width: auto;
    }

    .SectionHeader-Container-Name {
        order: 1;
    }

    .Divider-2 {
        display: block;
    }
}