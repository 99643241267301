.Time {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 6px;
    margin: 0;
    padding: 0;
}

.Circle {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
}

.CircleOut {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    /* background: radial-gradient(circle, rgba(102, 239, 100, 0.4),rgba(102, 239, 100, 0.1)); */
    animation: pulse 5s infinite reverse;
    border-radius: 100px;

}

.CircleIn {
    width: 6px;
    border-radius: 100px;
    height: 6px;
    background-color: rgba(102, 239, 100, 0.701);
}

@keyframes pulse {
    0% {
        background-color: rgba(102, 239, 100, 0);
        width: 12px;
        height: 12px;
    }

    25% {
        background-color: rgba(102, 239, 100, .3);
        width: 12px;
        height: 12px;
    }

    90% {
        background-color: rgba(102, 239, 100, .1);
    }

    100% {
        background-color: rgba(102, 239, 100, 0);
        width: 14px;
        height: 14px;
    }
  }