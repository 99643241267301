.StudyImage-Main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0px 0px 40px 0px;
    justify-content: center;
    align-items: center;
}

.StudyImage-Container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* Create a grid with auto-fit columns */
    gap: 32px; /* Adjust the gap between images as needed */
    max-width: 100%; /* Set the maximum width of the parent container */
    margin: 0 auto; /* Center the container horizontally */
}

.StudyImage-Container-Image {
    max-width: 100%; /* Ensure images don't exceed the parent's width */
    height: auto; 
}

@media (min-width: 600px){
    .StudyImage-Container {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* Create a grid with auto-fit columns */
    }
}


@media (min-width: 800px){
    .StudyImage-Container {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); /* Create a grid with auto-fit columns */
    }
}