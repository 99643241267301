.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--bg-color);
  box-sizing: border-box;
  padding: 0px 24px;
  overflow-x: hidden;
}

.App-Container {
  max-width: 740px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Space between items on the page */
  gap: 80px;
}

.App-Container-Top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 90px;
}

@media (min-width: 1200px) {
  .App {
      padding: 0px;
  }
}