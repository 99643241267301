.Work-Container {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.Work-Container-CaseStudies {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

@media (min-width: 740px) {
    .Work-Container-CaseStudies {
        gap: 88px;
    }
}