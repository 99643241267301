.Sideproject-Container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll !important;

}

@media (min-width: 740px) {
    .Sideproject-Container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        overflow-x: hidden;
        overflow-y: hidden !important;
    }
}