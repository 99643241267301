.Button-Container {
    width: 100%;
}

.Button-Link {
    width: 100%;
}

.Button {
    color: var(--beige-color);
    border-radius: 8px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 6px solid var(--gvns-dark, #181A27);
    background: #3D3D3D;
    box-shadow: 0px 4px 30px 10px rgba(200, 172, 50, 0.15);
    transition: box-shadow .5s, transform .4s;
    padding: 8px 24px;
    color: var(--beige-color);
    line-height: 180%;
    font-family: var(--body-font);
    width: 100%;
    font-weight: 500;
    cursor: pointer;
}

.Button:active {
    border-bottom: 6px solid var(--gvns-dark, #3D3D3D);
    box-shadow: 0px 4px 10px 4px rgba(200, 172, 50, 0);
    transform: scale(99%);
}

.Button-Link {
    text-decoration: none;
    border-bottom: none;
}

@media (min-width: 740px) {
    .Button {
        width: fit-content;
    }

    .Button-Link {
        width: auto;
    }

    .Button:hover {
        box-shadow: 0px 4px 15px 4px rgba(200, 172, 50, 0.45);
        transform: scale(1.01);
        transition: box-shadow 1s, transform .4s;
    }

    .Button:active {
        border-bottom: 6px solid var(--gvns-dark, #3D3D3D);
        box-shadow: 0px 4px 10px 4px rgba(200, 172, 50, 0);
        transform: scale(99%);
        transition: box-shadow .05s;
    }
}