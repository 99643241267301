.FixedTopBar-Container {
    width: 100%;
    padding-top: 48px;
    display: flex;
    justify-content: space-between;
    max-width: 740px;
    /* position:fixed; */
    z-index: 1;
}

.Logo {
    border-radius: 1000px;
    width: 48px;
}

.HomeButton {
    border-radius: 12px;
    padding: 2px;
    border: 0px;
    color: var(--body-color);
    background-color: rgba(0,0,0,0);
    transition: background-color 0.5s ease;
    cursor:pointer;
    border: 1px solid rgba(0,0,0,0);
}

.HomeButton:hover {
    background-color: rgba(0,0,0,.035);
    border: 1px solid rgba(0,0,0,.01);
}

.HomeButton:active {
    background-color: rgba(0,0,0,.08);
    border: 1px solid var(--img-bg);
}