.Announcement {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: var(--img-bg);
    border-radius: 16px;
    border: 1px solid #E9E9E9;
    box-shadow: 0 2px 10px rgba(104, 129, 146, 0.05);
    gap: 12px;
    transition: box-shadow 1s;
}

.Announcement:hover {
    box-shadow: 0 2px 20px rgba(104, 129, 146, 0.07);
    color: red;
}

.ContactLink {
    text-decoration: underline;
    transition: color .5s;
}

.ContactLink:hover {
    color: var(--title-color);
}

.Heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}